import { writable } from "svelte/store";

// types
import type { Nav } from "$types/types";

// import nav data
import { default as navBackupData } from "$data/nav.json";
const navData = navBackupData as Nav[];

// nav data store
export const navDataStore = writable(navData);
export const mobileNavOpen = writable(false);
export const activeMobileMenu = writable("main");
