<script lang="ts">
	// context api
	import { getContext } from "svelte";

	// store api
	import type { Writable } from "svelte/store";

	// components
	import LogoZenith from "$atoms/LogoZenith.svelte";

	// import stores via context api
	type BrandLink = Writable<HTMLAnchorElement | null>;
	const brandLinkStore: BrandLink = getContext("brandLinkStore");

	// css classes
	const linkBaseClasses = `block h-auto pb-1 px-2 rounded-md`;
	const linkFocusClasses = `focus:bg-primary-dark`;
	const btnFocusVisibleClasses = `focus-visible:outline focus-visible:outline-4 focus-visible:outline-accent focus-visible:outline-2 focus-visible:outline-neutral-100`;
	const btnHoverClasses = `hover:bg-primary`;

	// ref
	let brandLink: HTMLAnchorElement;

	// pass the anchor element to the store
	$: brandLinkStore.set(brandLink);
</script>

<template lang="pug">
	.flex.justify-start.items-center.w-full
		a#branding(
			class!="w-[168px] sm:w-[170px] xl:w-[178px] 2xl:w-[212px] {linkBaseClasses} {btnHoverClasses} {linkFocusClasses} {btnFocusVisibleClasses}",
			bind:this!="{ brandLink }",
			href="/",
			title="Go to homepage"
		)
			span.sr-only Zenith Energy
			LogoZenith
</template>
