<!--
@component
Footer link item.
-->

<script lang="ts">
	// props
	export let href = "";
	export let label = "";
	export let rel: string | null = null;
	export let title = "";
	export let target: string | null = null;

	// utils
	import { deslugify } from "$utils/utils";

	// declare variables
	let displayLabel: string;
	let displayTitle: string | null;
	let displayRel: string | null;
	let slug: string;

	// adjust href
	$: displayHref =
		href ==
		"https://workforcenow.adp.com/jobs/apply/posting.html?ccId=19000101_000001&client=A1NLX&lang=en_US&type=MP"
			? "/careers"
			: href;

	// derive slug from href
	$: slug = displayHref ? (displayHref.split("/").pop() as string) : "";

	// if no label is provided, use the slug to generate a label
	$: displayLabel = label
		? label
		: href
		? deslugify(slug)
				.replace(" Terminal", "")
				.replace("Ceo", "CEO")
				.replace("Jbbr", "JBBR")
				.replace("Lng", "LNG")
		: "";

	// if no title is provided, use the label to generate a title, else null
	$: displayTitle = title
		? title
		: displayLabel
		? "go to " + displayLabel + " page"
		: null;

	// if no rel is provided, use the href to generate a rel, else null
	$: displayRel = rel ? rel : href.includes("http") ? "external" : null;
</script>

<template lang="pug">
	+if('href && displayLabel')
		a.block.text-neutral-100.opacity-70.transition-opacity.underline-offset-4.outline-none(
			class="hover:opacity-100 hover:underline focus:underline",
			href!="{ href }",
			rel!="{ displayRel }",
			target!="{ target }",
			title!="{ displayTitle }"
		) { displayLabel }</template>
