<script lang="ts">
	// components
	import NavItemComponent from "$atoms/NavItem.svelte";
	import NavMenu from "$molecules/NavMenu.svelte";

	// types
	import type { NavItem } from "$types/types";

	// props
	export let nav: NavItem[];
	export let section = "";

	// variables
	let item: NavItem;
</script>

<template lang="pug">
	nav#mainNav.hidden(
		class="md:flex md:pt-[.35rem] min-h-[3.75rem] items-center xl:text-17 2xl:text-19 xl:min-h-[3.5rem]",
		class!="{ section == 'home' ? 'navbar-bg-home' : '' }"
	)
		+each('nav as item')
			+if('item?.menu')
				NavMenu(navItem!="{ item }")
				+else
					NavItemComponent(navItem!="{ item }")</template>
