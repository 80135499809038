<!--
@component
Here's some documentation for this component.
-->

<script lang="ts">
	// components
	import FooterLinkItem from "$atoms/FooterLinkItem.svelte";
	// types
	interface HeadingLink {
		classes?: string;
		href: string;
		label: string;
		title?: string | null;
		rel?: string | null;
	}

	// types
	import type { FooterListItem } from "$types/types";

	// props
	export let headingLink: HeadingLink | undefined = undefined;
	export let items: FooterListItem[] = [];

	// declare variables

	// heading tag
	$: headingTag = headingLink?.href ? "a" : "div";
	$: headingHref = headingLink?.href ? headingLink.href : null;
	$: headingTitle = headingLink?.title ? headingLink.title : null;
	$: headingRel = headingLink?.rel ? headingLink?.rel : null;
	$: headingClasses =
		headingTag === "a"
			? "cursor-pointer hover:underline underline-neutral-100/80 underline-offset-4"
			: "";
</script>

<template lang="pug">
	div
		svelte:element.block.font-medium.text-md.mb-4.text-neutral-100(
			class!="{ headingClasses }",
			href!="{ headingHref }",
			rel!="{ headingRel }",
			this!="{ headingTag }",
			title!="{ headingTitle }"
		)
			slot(name="heading")
		ul.grid.grid-cols-1.gap-2
			+each('items as item')
				li: FooterLinkItem(
					href!="{ item.href }",
					label!="{ item.label ? item.label : null }",
					rel!="{ item.rel ? item.rel : null }",
					target!="{ item.target ? item.target : null }",
					title!="{ item.title ? item.title : null }"
				)</template>
