<script lang="ts">
	//- imports
	import type { NavItem } from "$types/types";

	//- props
	export let navItem: NavItem;
</script>

<template lang="pug">
	a.block.text-neutral-50.text-opacity-80.ml-10.group(
		class="hover:text-opacity-100 !outline-none",
		href!="{ navItem.href }",
		rel!="{ navItem.rel }",
		title!="{ navItem.title }"
	)
		span(class="group-focus:underline underline-offset-4") { navItem.label }</template>
