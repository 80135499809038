<script lang="ts">
	// import css file
	import "/src/app.css";

	// import child page data
	import { page } from "$app/stores";

	// context api
	import { setContext } from "svelte";

	// import stores
	import { mobileNavOpen } from "$stores/navStore";

	// plausible analytics
	import PlausibleAnalytics from "$atoms/PlausibleAnalytics.svelte";

	// import components
	import GlobalNav from "$organisms/GlobalNav.svelte";
	import GlobalFooter from "$organisms/GlobalFooter.svelte";
	import MobileMenu from "$molecules/MobileMenu.svelte";

	// local types
	import type { LayoutData } from "./$types";

	// props
	export let data: LayoutData;

	// variables
	$: mobileNavContainerClasses = $mobileNavOpen
		? "translate-x-0 pointer-events-auto transition-transform"
		: "translate-x-[-100vw] pointer-events-none";
</script>

<template lang="pug">
	PlausibleAnalytics

	svelte:head
		title { $page.data.metaTitle ?? "Zenith Energy" }
		+if('$page.data.metaDescription')
			meta(
				content!="{ $page.data.metaDescription }",
				name="description"
			)
		+if('$page.data.metaNoIndex')
			meta(
				content="noindex",
				name="robots"
			)

	GlobalNav(
		isHome!="{ data.isHome }",
		nav!="{ data.nav }"
	)

	slot
	GlobalFooter(
		currentYear!="{ data.currentYear }",
		terminalsSlugs!="{ data.terminalsSlugs }"
	)

	//- mobile nav
	.fixed.top-0.left-0.z-30.h-screen.w-screen(
		class!="{mobileNavContainerClasses} md:hidden"
	)
		//- spacer for header
		.w-full.bg-primary(class="h-[60px]")

		//- mobile nav inner container
		.relative.w-full.h-full.pt-8.bg-neutral-100.shadow-xl
			MobileMenu(nav!="{ data.nav }")</template>
