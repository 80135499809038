<script lang="ts">
	// assets
	import logoSvg from "$assets/logo-zenith.svg";
	import logoPng from "$assets/logo-zenith.png";
</script>

<template lang="pug">
	picture
		source(
			srcset!="{ logoSvg }",
			type="image/svg+xml"
		)
		img.w-full.h-auto.select-none(
			alt="Zenith Energy logo",
			data-mimetype="image/png",
			draggable="false",
			height="33",
			loading="eager",
			role="presentation",
			src!="{ logoPng }",
			width="160"
		)
</template>
