<script lang="ts">
	// notes:
	// most of the footer content is static
	// the only dynamic content is the list of terminals
	// the list of terminals is passed in as a prop
	// and comes from the api
	// the list of terminals is used to generate the list of links
	// when a terminal is published or unpublished in the cms
	// the list of terminals is updated

	// components
	import FooterLinkItem from "$atoms/FooterLinkItem.svelte";
	import FooterLinksList from "$molecules/FooterLinksList.svelte";

	// types
	import type { FooterListItem } from "$types/types";

	// props
	export let usTerminalsSlugs = [
		"alamitos-terminal",
		"dominguez-hills-terminal",
		"gulf-lng",
		"jbbr-pipeline",
		"joliet-terminal",
		"long-beach-terminal",
		"portland-terminal",
	];
	export let currentYear = 2023;

	// constants
	const usTerminalsHeading: FooterListItem = {
		label: "US Terminals",
		href: "/north-america-terminals",
		title: "browse US terminals",
	};
	const usTerminalsLinks: FooterListItem[] = usTerminalsSlugs.map(
		(slug: string) => {
			return {
				href: "/terminals/" + slug,
			};
		},
	);
	const usAboutLinks: FooterListItem[] = [
		{ href: "/about/ceo-message" },
		{ href: "/about/our-team" },
		{ href: "/about/our-values" },
		{ href: "/about/responsibility" },
	];
	const usCareersLink =
		"https://workforcenow.adp.com/jobs/apply/posting.html?ccId=19000101_000001&client=A1NLX&lang=en_US&type=MP";

	//- Resources
	const resourcesListItems: FooterListItem[] = [
		{
			label: "Careers",
			href: usCareersLink,
			rel: "external noopener nofollow",
			target: "_blank",
		},
		{ href: "/contact" },
		{ href: "/news" },
	];
</script>

<template lang="pug">
	footer.page-x-padding.bg-dark.text-white.pt-16.relative.justify-self-end.mt-auto
		//- upper footer
		div
			//- list links
			.grid.grid-cols-1.mb-12.gap-y-8.text-base(
				class="sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
			)
				//- US Terminals
				FooterLinksList(
					headingLink!="{ usTerminalsHeading }",
					items!="{ usTerminalsLinks }"
				)
					svelte:fragment(slot="heading") US Terminals

				//- About
				div
					.mb-6
						FooterLinksList(items!="{ usAboutLinks }")
							svelte:fragment(slot="heading") About
					//-.mb-6
						FooterLinksList(items!="{ euAboutLinks }")
							svelte:fragment(slot="heading") Zenith EU

				//- Resources
				FooterLinksList(items!="{ resourcesListItems }")
					svelte:fragment(slot="heading") Resources

		//- lower footer
		.py-12.border-t.border-t-white.border-opacity-40
			.mb-4.flex.text-sm.gap-6
				+each('["/legal/terms","/legal/privacy"] as href')
					FooterLinkItem(
						href!="{ href }",
						rel="nofollow"
					)

			.justify-between(class="sm:flex")
				//- copyright
				.mb-12.mr-2.text-xs.text-white.text-opacity-60(class="sm:mb-4 xl:text-xxs")
					span.inline-block.mr-2 Copyright &#169; { currentYear } Zenith Energy Management LLC
					span All Rights Reserved.

				//- credits
				a.text-xs.text-white.text-opacity-60.transition-colors.outline-none(
					class="hover:text-accent hover:text-opacity-100 focus:underline focus:text-accent underline-offset-4 xl:text-xxs",
					href="https://lightningjar.com",
					rel="external",
					title="Lightning Jar digital studio"
				) website by ⚡️ Lightning Jar</template>
