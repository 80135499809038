<script lang="ts">
	// variables
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	let analytics = true;
	$: {
		analytics = process.env.NODE_ENV != "development";
	}
</script>

<template lang="pug">
	svelte:head
		+if('analytics')
			script(
				data-domain="zenithterminals.com",
				defer,
				src!="https://plausible.io/js/script.js"
			)
</template>
