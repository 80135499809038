<script lang="ts">
	// store api
	import { writable, type Writable } from "svelte/store";

	// context api
	import { setContext } from "svelte";

	// import components
	import NavLogoBlock from "$molecules/NavLogoBlock.svelte";
	import HamburgerButton from "$atoms/HamburgerButton.svelte";
	import MainNavbar from "$molecules/MainNavbar.svelte";

	// import stores
	import { mobileNavOpen } from "$stores/navStore";

	// import types
	import type { NavItem } from "$types/types";
	import type { BrandLinkStore } from "$types/types";

	// local store
	type BrandLinkStore = Writable<HTMLAnchorElement | null>;
	export const brandLinkStore: BrandLinkStore = writable(null);
	$: setContext("brandLinkStore", brandLinkStore);

	// props
	export let isHome = false;
	export let nav: NavItem[];

	// dynamic styles
	$: positionStyle = $mobileNavOpen
		? "fixed"
		: isHome
		? "absolute"
		: "relative";
</script>

<template lang="pug">
	header#top.z-50.border-b.border-b-neutral-50.border-opacity-40.w-full(
		class!="{ isHome && !$mobileNavOpen ? 'nav-bg-home' : 'bg-primary-dark' } hover:bg-primary-dark { positionStyle }"
	)
		//- Desktop
		div(class!="page-x-padding")
			.flex.justify-between.items-center.text-neutral-50(
				class="md:space-x-10 min-h-[3.75rem] xl:min-h-[3.5rem]"
			)
				NavLogoBlock
				HamburgerButton
				MainNavbar(nav!="{ nav }")</template>

<style>
	.nav-bg-home {
		background-image: linear-gradient(
			to right,
			rgb(123, 192, 33),
			hsla(220, 71%, 56%, 0),
			hsla(220, 71%, 56%, 0.6)
		);
		background-color: none;
	}
</style>
